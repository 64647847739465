const initialState = {
  fetching: false,
  fetched: false,
  data: [],
  error: false,
};
const lenders = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LENDERS_PENDING":
      return {
        ...state,
        fetching: true,
      };

    case "GET_LENDERS_DONE":
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload,
      };
    case "GET_LENDERS_ERROR":
      return {
        ...state,
        error: true,
      };
    default:
      return state;
  }
};
export default lenders;
