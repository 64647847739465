const initialState = {
    fetching: false,
    fetched: false,
    error: false
}

const agreedCompletion = (state = initialState, action) => {
    switch (action.type) {
        case "AGREED_COMPLETION_PENDING":
            return {
                ...state,
                fetching: true
            }
        case "AGREED_COMPLETION_DONE":
            return {
                ...state,
                fetching: false,
                fetched: true,
                payload: action.payload
            }
        case "AGREED_COMPLETION_ERROR":
            return {
                ...state,
                fetching: false,
                error: true
            }
        default:
            return state
    }
}

export default agreedCompletion
