const initialState = {
  fetching: false,
  fetched: false,
  error: false,
};
const submitAEReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SUBMIT_AE_PENDING":
      return {
        ...state,
        fetching: true,
      };
    case "SUBMIT_AE_DONE":
      return {
        ...state,
        fetching: false,
        fetched: true,
        payload: action.payload,
      };
    case "SUBMIT_AE_ERROR":
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
export default submitAEReducer;
