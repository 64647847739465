const initialState = {
  fetching: false,
  fetched: false,
  error: false,
};
const submitNpsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SUBMIT_NPS_PENDING":
      return {
        ...state,
        fetching: true,
      };
    case "SUBMIT_NPS_DONE":
      return {
        ...state,
        fetching: false,
        fetched: true,
        payload: action.payload,
      };
    case "SUBMIT_NPS_ERROR":
      return {
        ...state,
        fetching: false,
        error: true,
      };
    default:
      return state;
  }
};
export default submitNpsReducer;
