const initalState = {
  fetching: false,
  fetched: false,
  error: false
}

const giftReducer = (state = initalState, action) => {
  switch (action.type) {
    case 'GIFT-SUBMIT-LOADING':
      return {
        ...state,
        fetching: true
      }
    case 'GIFT-SUBMIT-SUCCESS':
      return {
        ...state,
        fetching: false,
        fetched: true,
        payload: action.payload
      }
    case 'GIFT-SUBMIT-ERROR':
      return {
        ...state,
        fetching: false,
        error: true
      }
    default:
      return state
  }
}

export default giftReducer
