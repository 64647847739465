// ** Initial State
const initialState = {
  colored: {
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false
  },
  matterType: '',
  soldOrNotSold: '',

  referralAgent: '',
  negotiator: {
    value: '',
    isRequired: false
  },
  feeEarner: '',
  saleFee: '',
  purchaseFee: '',

  clientTitle: '',
  clientFirstName: '',
  clientMiddleName: '',
  clientLastName: '',
  clientMobile: '',
  clientEmail: '',
  telVoiceEmail: '',
  bestTime: '',

  saleAgent: '',
  purchaseAgent: '',

  saleProperty: {
    salePropertyPrice: '',
    saleType: '',
    saleAddressEntry: 'saleAutoAddressEntry',
    saleAutoPostcode: {
      value: '',
      isRequired: false
    },
    saleAutoAddressSelectBox: {
      value: '',
      isRequired: false
    },
    salePostcode: '',
    saleAddressLine1: '',
    saleAddressLine2: '',
    saleAddressLine3: ''
  },
  purchaseProperty: {
    purchase_property_price: '',
    purchaseType: '',
    purchaseSecondProperty: '',
    purchaseAddressCheck: '',
    purchaseAddressEntry: '',
    purchaseAutoPostcode: {
      value: '',
      isRequired: false
    },
    purchaseAutoAddressSelectBox: {
      value: '',
      isRequired: false
    },
    purchasePostcode: '',
    purchaseAddressLine1: '',
    purchaseAddressLine2: '',
    purchaseAddressLine3: ''
  }

}

const quoteFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_MATTER_TYPE':
      return { ...state, matterType: action.val }
    case 'UPDATE_SOLD_OR_NOTSOLD_TYPE':
      return { ...state, soldOrNotSold: action.val }


    case 'UPDATE_REFERRAL_AGENT':
      return { ...state, referralAgent: action.val }
    case 'UPDATE_NEGOTIATOR':
      return { ...state, negotiator: { value: action.val, isRequired: state.negotiator.isRequired } }
    case 'UPDATE_NEGOTIATOR_REQUIRED':
      return { ...state, negotiator: { value: state.negotiator.value, isRequired: action.val } }
    case 'UPDATE_FEE_EARNER':
      return { ...state, feeEarner: action.val }
    case 'UPDATE_SALE_FEE':
      return { ...state, saleFee: action.val }
    case 'UPDATE_PURCHASE_FEE':
      return { ...state, purchaseFee: action.val }


    case 'UPDATE_COLORED_STEP1':
      return { ...state, colored: { ...state.colored, step1: action.val } }
    case 'UPDATE_COLORED_STEP2':
      return { ...state, colored: { ...state.colored, step2: action.val } }
    case 'UPDATE_COLORED_STEP3':
      return { ...state, colored: { ...state.colored, step3: action.val } }
    case 'UPDATE_COLORED_STEP4':
      return { ...state, colored: { ...state.colored, step4: action.val } }
    case 'UPDATE_COLORED_STEP5':
      return { ...state, colored: { ...state.colored, step5: action.val } }


    case 'UPDATE_CLIENT_TITLE':
      return { ...state, clientTitle: action.val }
    case 'UPDATE_CLIENT_FIRSTNAME':
      return { ...state, clientFirstName: action.val }
    case 'UPDATE_CLIENT_MIDDLENAME':
      return { ...state, clientMiddleName: action.val }
    case 'UPDATE_CLIENT_LASTNAME':
      return { ...state, clientLastName: action.val }
    case 'UPDATE_CLIENT_MOBILE':
      return { ...state, clientMobile: action.val }
    case 'UPDATE_CLIENT_EMAIL':
      return { ...state, clientEmail: action.val }
    case 'UPDATE_CLIENT_VOICE_EMAIL':
      return { ...state, telVoiceEmail: action.val }
    case 'UPDATE_CLIENT_BEST_TIME':
      return { ...state, bestTime: action.val }


    case 'UPDATE_SALE_AGENT':
      return { ...state, saleAgent: action.val }
    case 'UPDATE_PURCHASE_AGENT':
      return { ...state, purchaseAgent: action.val }


    case 'UPDATE_SALE_PROPERTY_PRICE':
      return { ...state, salePropertyPrice: action.val }
    case 'UPDATE_SALE_TYPE':
      return { ...state, saleType: action.val }
    case 'UPDATE_SALE_ADDRESS_ENTRY':
      return { ...state, saleAddressEntry: action.val }

    default:
      return state
  }
}

export default quoteFormReducer
