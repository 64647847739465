const initialState = {
    fetching: false,
    fetched: false,
    error: false,
  };
  const submitVpafReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SUBMIT_VPAF_PENDING":
        return {
          ...state,
          fetching: true,
        };
      case "SUBMIT_VPAF_DONE":
        return {
          ...state,
          fetching: false,
          fetched: true,
          payload: action.payload,
        };
      case "SUBMIT_VPAF_ERROR":
        return {
          ...state,
          fetching: false,
          error: true,
        };
      default:
        return state;
    }
  };
  export default submitVpafReducer;
  