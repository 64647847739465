// ** Redux Imports
import { combineReducers } from 'redux'
import agreedCompletion from '../reducers/agreedCompletionDate'
import submitAESale from './additionalEnquiries'
// ** Reducers Imports
import auth from './auth'
import submitCompletionDate from './completion_date'
import ContinuationForm from './continuation-form'
import submitEstateAgency from './estateAgency'
import estateAgencyQuery from './estateAgencyQuery'
import submitFif from './fif'
import formsQuery from './formsQuery'
import getCompletionDate from './getCompletionDate'
import getHowAreWeDoing from './getHowAreWeDoing'
import getNpsReducer from './getNps'
import giftReducer from './gift'
import submitHowAreWeDoing from './how_are_we_doing'
import submitInstruction from './instruction'
import validatePhoneNumber from './instruction/validPhone'
import instructionQuery from './instructionQuery'
import jsonForm from './jsonForm'
import landlords from './landlords'
import layout from './layout'
import lenders from './lenders'
import managementCompany from './managementCompany'
import navbar from './navbar'
import submitNps from './nps'
import submitPid from './pid'
import submitPif from './pif'
import submitPreInstruction from './preInstruction'
import preInstructionQuery from './preInstructionQuery'
import quoteForm from './quoteForm'
import quotes from './quotes'
import submitReferralFee from './referral_fee'
import referralFeeAgreement from './referral_fee_agreement'
import referralFeeQuery from './referral_fee_query'
import submitSlifa from './slifa'
import sourceOfFunds from './source-of-funds'
import submitStaffContact from './staffContact'
import staffContactQuery from './staffContactQuery'
import tourReducer from './tour'
import yourPaymentDetails from './your_payment_details'
import autoSave from "../reducers/autoSave"
import records from "../reducers/records"
import submitVpaf from "./verifyPurchaseAddress"
const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  quoteForm,
  quotes,
  jsonForm,
  formsQuery,
  yourPaymentDetails,
  submitPif,
  submitPid,
  submitInstruction,
  validatePhoneNumber,
  submitFif,
  submitSlifa,
  tourReducer,
  landlords,
  managementCompany,
  lenders,
  submitCompletionDate,
  submitHowAreWeDoing,
  submitNps,
  getNps: getNpsReducer,
  getCompletionDate,
  getHowAreWeDoing,
  instructionQuery,
  submitPreInstruction,
  preInstructionQuery,
  staffContactQuery,
  submitStaffContact,
  submitEstateAgency,
  estateAgencyQuery,
  referralFeeQuery,
  referralFeeAgreement,
  submitReferralFee,
  sourceOfFunds,
  ContinuationForm,
  submitAESale,
  agreedCompletion,
  gift: giftReducer,
  autoSave,
  records,
  submitVpaf
})

export default rootReducer
