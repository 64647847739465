import { useState } from 'react'
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Button, Input, InputGroup, InputGroupAddon } from 'reactstrap'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { toast } from 'react-toastify'
import Avatar from '@components/avatar'
import { Check } from 'react-feather'

import logoLight from "../../assets/images/logo/logo.svg";
import chrome from "../../assets/images/svg/chrome.svg";
import firefox from "../../assets/images/svg/firefox.svg";
import opera from "../../assets/images/svg/opera.svg";
import edge from "../../assets/images/svg/edge.svg";
import safari from "../../assets/images/svg/safari.svg";

import "./index.css";

const ToastSuccess = () => (
  <>
    <div className='toastify-header pb-0'>
      <div className='title-wrapper'>
        <Avatar size='sm' color='success' icon={<Check />} />
        <h6 className='toast-title'>Copied To Clipboard !</h6>
      </div>
    </div>
  </>
)


const NotSupport = () => {
  const [value, setValue] = useState(window.location.href)
  const [copied, setCopied] = useState(false)

  const handleCopy = ({ target: { value } }) => {
    setValue(value)
    setCopied(false)
  }

  const onCopy = () => {
    setCopied(true)
    toast.success(<ToastSuccess />, {
      autoClose: 3000,
      hideProgressBar: true,
      closeButton: false
    })
  }

  return (
    <main class="bsod container">
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <img src={logoLight} alt="avrillo logo" className="logo" width="150" />
      </div>
      <h2 class="neg title text-center"><span class="bg">Your Internet Explorer is no longer supported by Microsoft.</span></h2>
      <p>Simply copy the link below and paste it to address bar in another browser:</p>
      <div>
        <Row className="mb-4">
          <Col md="12">
            <InputGroup>
              <Input value={value} onChange={handleCopy} readOnly onClick={(e) => e.target.select()} />
              <InputGroupAddon addonType='append'>
                <CopyToClipboard onCopy={onCopy} text={value}>
                  <Button color='primary' outline>
                    Copy!
                  </Button>
                </CopyToClipboard>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
      </div>
      <p>To continue use an alternative browser for example any of these below:</p>
      <nav class="nav justify-content-center">
        <a href="https://www.google.com/intl/en/chrome/" class="link"><img className="browser-ico" src={chrome} /> <span>Chrome</span></a>&nbsp;
        |&nbsp;<a href="https://www.mozilla.org/en-US/firefox/new/" class="link"><img className="browser-ico" src={firefox} />Firefox</a>&nbsp;
        |&nbsp;<a href="https://www.opera.com" class="link"><img className="browser-ico" src={opera} />Opera</a>&nbsp;
        |&nbsp;<a href="https://www.microsoft.com/en-us/edge" class="link"><img className="browser-ico" src={edge} />Microsoft Edge</a>&nbsp;
        |&nbsp;<a href="https://www.apple.com/uk/safari/" class="link"><img className="browser-ico" src={safari} />Safari (for MacOS)</a>
      </nav>
      <h2 class="neg title"><span class="bg">What does this mean?</span></h2>
      <p>It’s time to act. Since 1/12/16, Microsoft no longer provides security updates or technical support for old versions of Internet Explorer. Regular security updates help protect devices so upgrading and staying current is important.</p>
      <p>Please see details here:<br></br> <a style={{ color: "#7fad40" }} href="https://www.microsoft.com/en-ww/microsoft-365/windows/end-of-ie-support">https://www.microsoft.com/en-ww/microsoft-365/windows/end-of-ie-support</a></p>

    </main>
  );
};

export default NotSupport;