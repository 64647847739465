// ** Initial State
const initialState = []

const jsonFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_VALUE':
      const newValue = []

       newValue[action.name] = action.val
      return [...state, ["abs"]]

    default:
      return state
  }
}

export default jsonFormReducer
