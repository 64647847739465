const initialState = {
  fetching: false,
  fetched: false,
  data: {},
  error: false,
};
const referralFeeAgreement = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REFERRAL_FEE_AGREEMENT_PENDING":
      return {
        ...state,
        fetching: true,
      };
    case "GET_REFERRAL_FEE_AGREEMENT_DONE":
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload
      };
    case "GET_REFERRAL_FEE_AGREEMENT_ERROR":
      return {
        ...state,
        fetching: false,
        error: true,
        data: action.payload,
      };
    case 'SUBMIT_REFERRAL_FEE_AGREEMENT_DONE':
      return {
        ...state,
        submitStatus: 'success',
        fetching: false
      }
    case "SUBMIT_REFERRAL_FEE_AGREEMENT_PENDING":
      return {
        ...state,
        fetching: true
      }
    case 'SUBMIT_REFERRAL_FEE_AGREEMENT_ERROR':
      return {
        ...state,
        submitStatus: 'fail',
        error: action.payload,
        fetching: false,
      }
    default:
      return state;
  }
};
export default referralFeeAgreement;
