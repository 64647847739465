const initialState = {
  fetching: false,
  fetched: false,
  fetchedRef: "",
  data: {},
  error: false,
};
const estateAgencyQuery = (state = initialState, action) => {
  switch (action.type) {
    case "ESTATE_AGENCY_QUERY_PENDING":
      return {
        ...state,
        fetching: true,
      };
    case "ESTATE_AGENCY_QUERY_DONE":
      return {
        ...state,
        fetching: false,
        fetched: true,
        fetchedRef: action.payload.case_ref,
      };
    case "ESTATE_AGENCY_QUERY_ERROR":
      return {
        ...state,
        fetching: false,
        error: true,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default estateAgencyQuery;
