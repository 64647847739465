const initialState = {
  fetching: false,
  fetched: false,
  data: {},
  error: false,
  submitStatus: null
}
const YourPaymentDetails = (state = initialState, action) => {
  switch (action.type) {
    case 'ESTATE_AGENT_DETAILS_PENDING':
      return {
        ...state,
        fetching: true
      }
    case 'ESTATE_AGENT_DETAILS_DONE':
      return {
        ...state,
        fetching: false,
        fetched: true,
        data: action.payload,
        timestamp: Math.round(Date.now() / 1000)
      }
    case 'ESTATE_AGENT_DETAILS_ERROR':
      return {
        ...state,
        fetching: false,
        error: true,
        data: action.payload
      }
    case 'SUBMIT_ESTATE_AGENT_DETAILS_DONE':
      return {
        ...state,
        submitStatus: 'success',
        fetching: false
      }
    case "SUBMIT_ESTATE_AGENT_DETAILS_PENDING":
        return {
          ...state,
          fetching: true
        }
    case 'SUBMIT_ESTATE_AGENT_DETAILS_ERROR':
      return {
        ...state,
        submitStatus: 'fail',
        error: action.payload,
        fetching: false,
      }
    default:
      return state
  }
}

export default YourPaymentDetails
