const initialState = {
  fetching: false,
  fetched: false,
  data: {},
  error: false,
};
const instructionQueryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "INSTRUCTION_QUERY_PENDING":
      return {
        ...state,
        fetching: true,
      };
    case "INSTRUCTION_QUERY_DONE":
      return {
        ...state,
        fetching: false,
        fetched: true,
      };
    case "INSTRUCTION_QUERY_ERROR":
      return {
        ...state,
        fetching: false,
        error: true,
        data: action.payload,
      };
    default:
      return state;
  }
};
export default instructionQueryReducer;
